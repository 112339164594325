var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container topCon"},[_vm._m(0),_c('div',{staticClass:"content"},[_c('div',{staticClass:"typeDiv"},[_vm._m(1),_vm._m(2),_c('div',[_c('el-button',{staticClass:"btn disableBtn",attrs:{"disabled":""},on:{"click":function($event){return _vm.chooseType('SUBJECT_TYPE_INDIVIDUAL')}}},[_vm._v("选择")])],1)]),_c('div',{staticClass:"typeDiv"},[_vm._m(3),_vm._m(4),_c('div',[_c('el-button',{staticClass:"btn",on:{"click":function($event){return _vm.chooseType('SUBJECT_TYPE_ENTERPRISE')}}},[_vm._v("选择")])],1)]),_c('div',{staticClass:"typeDiv"},[_vm._m(5),_vm._m(6),_c('div',[_c('el-button',{staticClass:"btn disableBtn",attrs:{"disabled":""},on:{"click":function($event){return _vm.chooseType('SUBJECT_TYPE_GOVERNMENT')}}},[_vm._v("选择")])],1)]),_c('div',{staticClass:"typeDiv"},[_vm._m(7),_vm._m(8),_c('div',[_c('el-button',{staticClass:"btn disableBtn",attrs:{"disabled":""},on:{"click":function($event){return _vm.chooseType('SUBJECT_TYPE_INSTITUTIONS')}}},[_vm._v("选择")])],1)]),_c('div',{staticClass:"typeDiv"},[_vm._m(9),_vm._m(10),_c('div',[_c('el-button',{staticClass:"btn disableBtn",attrs:{"disabled":""},on:{"click":function($event){return _vm.chooseType('SUBJECT_TYPE_OTHERS')}}},[_vm._v("选择")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ztTop"},[_c('span',{staticClass:"ztTitle"},[_vm._v("选择主体类型")]),_c('a',{staticClass:"ztzy",attrs:{"target":"_blank","href":"https://kf.qq.com/faq/180910IBZVnQ180910naQ77b.html"}},[_vm._v("选择主体指引")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("个体工商户")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"typeDetail"},[_vm._v("营业执照上的主体类型一般为个体户、个体工商户、个体经营")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("企业")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"typeDetail"},[_vm._v("营业执照上的主体类型一般为有限公司、有限责任公司")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("事业单位")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"typeDetail"},[_vm._v("包括国内各类事业单位，如：医疗、教育、学校等单位")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("社会组织")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"typeDetail"},[_vm._v("包括社会团体、民办非企业、基金会、基层群众性自治组织、农村集体经济组织等组织")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("政府机关")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"typeDetail"},[_vm._v("包括国内各级、各类政府机关，如：机关党委、税务、民政、人社、工商、商务、市监等")])])
}]

export { render, staticRenderFns }