<template>
  <div class="container topCon">
    <div class="ztTop"><span class="ztTitle">选择主体类型</span><a target="_blank" class="ztzy" href="https://kf.qq.com/faq/180910IBZVnQ180910naQ77b.html">选择主体指引</a></div>
    <div class="content">
      <div class="typeDiv">
        <div><h3>个体工商户</h3></div>
        <div><p class="typeDetail">营业执照上的主体类型一般为个体户、个体工商户、个体经营</p></div>
        <div><el-button class="btn disableBtn" @click="chooseType('SUBJECT_TYPE_INDIVIDUAL')" disabled >选择</el-button></div>
      </div>
      <div class="typeDiv">
        <div><h3>企业</h3></div>
        <div><p class="typeDetail">营业执照上的主体类型一般为有限公司、有限责任公司</p></div>
        <div><el-button class="btn" @click="chooseType('SUBJECT_TYPE_ENTERPRISE')">选择</el-button></div>
      </div>
      <div class="typeDiv">
        <div><h3>事业单位</h3></div>
        <div><p class="typeDetail">包括国内各类事业单位，如：医疗、教育、学校等单位</p></div>
        <div><el-button class="btn disableBtn" @click="chooseType('SUBJECT_TYPE_GOVERNMENT')" disabled>选择</el-button></div>
      </div>
      <div class="typeDiv">
        <div><h3>社会组织</h3></div>
        <div><p class="typeDetail">包括社会团体、民办非企业、基金会、基层群众性自治组织、农村集体经济组织等组织</p></div>
        <div><el-button class="btn disableBtn" @click="chooseType('SUBJECT_TYPE_INSTITUTIONS')" disabled>选择</el-button></div>
      </div>
      <div class="typeDiv">
        <div><h3>政府机关</h3></div>
        <div><p class="typeDetail">包括国内各级、各类政府机关，如：机关党委、税务、民政、人社、工商、商务、市监等</p></div>
        <div><el-button class="btn disableBtn" @click="chooseType('SUBJECT_TYPE_OTHERS')" disabled>选择</el-button></div>
      </div>
    </div>

  </div>
</template>

<script>
import {addWxMerchant} from "../../api/wxMerchant";

export default {
  name: "merchantApply",
  components: {},
  data() {
    let info = JSON.parse(localStorage.getItem('info'));
    return {};

  },
  computed: {},
  watch: {},
  created() {
    // console.log('1111',this.$router.history.current.query)
    if (this.$router.history.current.query.isSourceAdmin) {
      localStorage.setItem('isSourceAdmin', this.$router.history.current.query.isSourceAdmin);
    }
  },
  methods: {
    async chooseType(type){
      let param = {
        "subjectType":type
      }
      addWxMerchant(param).then(response=>{
        this.$router.push({path:'/merchantApplyInfo',query: {merchantId:response.data.merchantId,businessCode:response.data.businessCode,businessInfoFinish:response.data.businessInfoFinish,contactInfoFinish:response.data.contactInfoFinish,createTime:response.data.createTime,settlementInfoFinish:response.data.settlementInfoFinish,subjectInfoFinish:response.data.subjectInfoFinish,subjectType:response.data.subjectType,isSee:false}})
      })
    },
    closeDialog() {
      this.exportDialogVisible = false
      this.ruleForm.exportTime = null
      this.ruleForm.source = ""
    },
  }
};
</script>

<style scoped>
.ztTitle{
  font-size: 18px;
}
.ztzy{
  display: inline-block;
  margin-left: 20px;
  font-size: 14px;
  color: #1b1bae;
}
.topCon {
  position: relative;

}
.typeDiv{
  width: 314px;
  float: left;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin: 30px 25px;
  padding: 25px 20px 30px;
  text-align: center;
  font-size: 14px;
}
.typeDetail{
  color: #999;
  line-height: 18px;
  margin: 10px 0 25px;
  height: 54px;
}
.btn{
  color: #fff;
  background-color: #00c250;
  border-color: #00c250;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: .1s;
  font-weight: 500;
  padding: 10px 32px;
  font-size: 14px;
  border-radius: 4px;
}
.exportBtn {
  position: absolute;
  right: 10px;
  top: 10px;
}
.disableBtn{
  background-color: #8c939d;
}
</style>
